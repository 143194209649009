import Continueconvo from "../../components/continueconvo";

function Milestones() {
    return (
        <div class="container">

            <br></br>

            <div class="row"> 

                <div class="col">
                <div class="container">
                    <p><br></br><h1>Milestones</h1><br></br></p>
                    <p class="fs-6 text-muted">
                    - October 2024
                    </p>
                </div>
                </div>
                <div class="col-2">
                <br></br>
                    <img src={process.env.PUBLIC_URL+"/lwv.jpg"} class="img-fluid" alt="milestones"></img>
                <br></br>
                <br></br>
                </div>  
            </div>

            <hr class="my-3"></hr>


            <div class="container">
                <br></br>
                <p>
                What differentiates successful outcomes in a world with limited resources. One heuristic I use to address this I refer to as a milestone.</p>
                <br></br>
                <h3>Defining a milestone</h3>
                <p>
                A milestone is defined as ‘a significant stage or event in the development of something’. I use it specifically as the inflection point where a trend can be described by a binary rather than a continuous trend.                    
                <br></br><br></br>
                A milestone can be:
                    <br></br>
                    <ul>
                        <li>When a battery lasts ‘all day’</li>
                        <li>When your media files are served fast enough to ‘stream’ in ‘real time’</li>
                    </ul>
                </p>
                <br></br>
                <h3>Why it matters</h3>
                <p>
                Both Capital and effort are poured into popular trends and do yield incremental results. But not all increments are equal and it's rare that incremental outcomes win markets. What wins over customers are reaching meaningful milestones that bring noticeable changes to their experience.                    
                <br></br><br></br>
                    
                This isn’t an entirely new concept. Similar to launching an MVP, going from 0 to 1, or working from first principles,. Milestones are the boundaries to prioritise and optimise across all factors of a product. An abstraction that ties these ideas together. In reality this means quantifying a target that must be hit or avoiding bottlenecks before they ever become an issue.
                <br></br><br></br>
                With limited resources, being distracted by the wrong factors can mean you fall short of what matters. Identifying milestones is a technique to make globally optimal decisions and not get caught chasing local wins that are ultimately pitfalls.
                </p>
                <br></br>
                <h3>Examples from the market</h3>
                <p>
                Here are some examples from the market viewed through this lens.
                </p>
                <h5>Digital Technology</h5>
                <p>
                All digital technology is limited by hardware. This hardware has a relatively fixed and predictable development timeline. Semiconductor nodes, architectures, and chips are planned on a comparatively long-term basis even before discussing  the market share of price points and device categories. These milestones can define a market segment and the viability long before the strength of any software can be tested.
                </p>
                <h5>Apples's move to Arm</h5>
                <p>
                Apple moving to its own ARM-based chips for its Mac devices was a bold but a decisive success. While it will trade benchmarks with x86 and ARM competition, there is reason to believe the M-series will persist as a favourite among consumers. They did away with ever higher benchmarks and focused on performance per watt. The results were maximised single threading so it always feels ‘snappy’, accelerated hardware ensuring media editing competes in its own category, battery life easily 'lasts a day', devices that are fanless and therefore ‘silent’. A lot of trade offs were made but ultimately it was optimised for categorical facts that matter to consumers. 
                </p>
                <h5>Apple’s marketing and design</h5>
                <p>
                Apple as a company consistently matches product decisions with the right marketing. Take their ‘Retina Display’ branding. When it comes to screen resolution, it is pixel density not absolute resolution that matters. Beyond a certain point, a user can’t tell the difference but a higher density drains battery and adds cost. Most competitors will reach for popular standards like FHD and 4K regardless. Apple however has rewritten that continuous trend into a categorical feature and has the marketing prowess to ensure their customers trust it.
                </p>
                <h5>Smartphones</h5>
                <p>
                In mobiles, many brands are going for mid-range pricing points for their flagship devices. This coincides with customers buying phones less frequently. For most consumers, mid-range devices now tick all boxes. Aside from mobile games, a mid-range chip will run ‘every app’ and do it ‘instantaneously’ from our user experience perspective. Some will categorically want the best camera for moments that will only happen once, to be able to play any game, or just the novelty of being in the category of best. The market itself is increasingly defined by these categorical limits.
                </p>
                <h5>Tesla and EV viability </h5>
                <p>
                Tesla transformed an industry by making the EV viable. This milestone in itself is made of several components. When you have to simultaneously price a car from $35,000, achieve 250 miles of range, and build a charging network enabling inter-state travel, you will have to make concessions. In such a tough market, this focus is arguably why they succeeded. Tesla did away with the endless increments of the industry and prioritised the milestone that weighed heavier for consumers.
                </p>
                <h5>Social media</h5>
                <p>
                You can build a technically better social media platform, but you can’t substitute the networks that make them work. You either have all your friends or content creators on a platform or you don’t. The same binary applies for content format. Users only have a limited  attention span for all platforms, any that fall short are substituted for another. Both milestones are a factor to users, but it increasingly seems that as far as content is concerned, it’s the latter that is winning.
                </p>
                <h5>Streaming services</h5>
                <p>
                On streaming services, users don’t subscribe because you have a slightly larger catalogue of generic licenced content. Users subscribe because you categorically have it at all. Both the new entrants and incumbents in this market have heavily relied on this, you only have to look at the scale of investment in exclusive rights to content makers and franchises.
                </p>
                <h5>Blockchain-based products</h5>
                <p>
                Blockchain-based products face consistent trade-offs with the centralised web. As a result, use cases are tied to the incremental improvements to the underlying compute and networking infrastructure of the internet. Blockchain use cases will continue to grow but will be bounded by that trade-off. For evaluating use cases, the higher-level milestone is what makes the difference to users; the fact it is decentralised, or that benefits of centralisation (such as speed) matter more.
                </p>
                <br></br>
                <h3>Implications</h3>
                <p>
                There is a point where a feature becomes a commodity and when it does, there is little to gain from pursuing it further. So focus on the next area of differentiation and find the most significant boundary to break.
                </p>

                <br></br>   
            </div>

            <Continueconvo />

            <div class="container">
                <p class="text-muted" style={{ 'font-size':'70%' }}>
                The views expressed here are those of the auther and do not necessarily represent the views of any affiliated individuals or entities. 
                The authors and/or any associates of this site assume no responsibility or liability for any errors or omissions in the content of this site and cannot be responsible for any use of the information contained on this site.
                </p>
            </div>



        </div>

    );
  }
  
  export default Milestones;