import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from "./pages/home";
import About from "./pages/about";
import Blog from "./pages/blog";
import Milestones from "./pages/blog/milestones";

function AppContent() {
  const location = useLocation();
  const isHome = location.pathname === '/';
  
  return (
    <div className="App container-fluid d-flex flex-column min-vh-100">
      {!isHome && <Header />}
      <hr />
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="about" element={<About />}/>
        <Route path="blog" element={<Blog />}/>
        <Route path="blog/milestones" element={<Milestones />}/>
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;