import { twitterlink } from "../config";

function About() {
    return (
        <div class="container-fluid">

        <br></br>
            <div class="container">
                <p><br></br><h1 className="page-header">About</h1><br></br></p>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col">
                    <p>
                        <br></br>
                        <br></br>
                            I build, analyse, & explore.
                            <br></br>
                            This is a space to share that experience.
                            <br></br>
                            <br></br>
                    </p>
                    </div>        
                    <div class="col">
                        <p class="text-end"> ¬ </p>
                        <br></br> <p class="text-end"></p>
                    </div>
                </div>
            </div>
    {
    // Separator
    }
            <div className="container">
                <div className="row">
                    <div className="col">
                    <hr></hr>
                    </div>
                    <div className="col">
                        
                    </div>
                    <div className="col">

                    </div>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col">
                        <p>
                            <br></br>
                            Reach out: 
                            <br></br>
                            <a className="twitter-follow-button" data-show-count="false" target="_blank" rel="noopener noreferrer" href={twitterlink}>
                                <img src={process.env.PUBLIC_URL+"/icons/twittericon.svg"} className='socialicons' alt=""></img>
                            </a>
                            <a className="" href="mailto:contact@z37k.com" target="_blank" rel="noopener noreferrer">
                                <img src={process.env.PUBLIC_URL+"/icons/emailicon.svg"} className='socialicons' alt=""></img>
                            </a>
                        </p>
                    </div>       
           
                    <div class="col-1">
                        <br></br><p class="text-end">  </p><br></br> <br></br> <br></br><br></br><br></br><br></br>
                        <br></br><br></br> <p class="text-end"> . <br></br></p>
                    </div>
                </div>
            </div>

        </div>
    );
  }
  
  export default About;