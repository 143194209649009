import { Link } from "react-router-dom";

function Home() {
    return (
        <div class="container-fluid justify-content-center">

        <br></br>      
        <br></br>

            <div class="homecontainer">

                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tg60.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons">-<br></br><br></br><br></br><br></br><br></br><br></br>.</div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tr80.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text fjalla">
                        <div class="text-start">
                            <Link to="/">Z37K</Link>
                        </div>
                        <hr></hr>
                        <div class="text-center">
                            <Link to="/blog">Blog</Link>

                        </div>
                        <hr></hr>
                        <div class="text-end">
                            <Link to="/about">About</Link>
                        </div>
                    </div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tp50.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons text-end">¬ <br></br><br></br><br></br><br></br><br></br><br></br>..</div>
                </div>
            </div>

        <br></br>

        </div>
    );
  }
  
  export default Home;